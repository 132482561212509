import axios from 'axios';
import { BASE_URL } from '../utils/const';

const headers = {
    // "Access-Control-Allow-Origin" : "*",
    // "Access-Control-Allow-Methods" : "GET,POST,PUT,DELETE,OPTIONS",
    // "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
}
const gAxios = axios.create({
    headers: headers,
    baseURL: BASE_URL,
    timeout:20000,
})

export default gAxios;