import gAxios from '../../hooks/useAxios'
import { GET_ALBUM, GET_IMAGES } from '../../utils/const'


export const getGallery = async (year: string | number) => {
    try {
        let params = {
            year: year
        }
        const response = await gAxios.get(GET_ALBUM, {
            params
        })
        return response.data
    } catch (error) {
        console.error('Error', error)
    }
}

export const getImages = async (year:string,album: string) => {
    try {
        let params = {
            year: year,
            album: album
        }
        const response = await gAxios.get(GET_IMAGES, {
            params
        })
        return response.data
    } catch (error) {
        console.error('Error', error)
    }
}

