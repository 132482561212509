import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, styled,CircularProgress } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import { useState } from 'react';
import { getGallery, getImages } from '../api/api';
import { GET_LOGO, MAVINTERNATIONAL, MAVVIDYASHRAM } from '../../utils/const';



const HeaderComp = (props: any) => {
    const { handleImages, year, setYear, selectedAlbum, setSelectedAlbum, isInternationalURL } = props;

    const presentYear = new Date().getFullYear() + 1;
        const [loading,setLoading] = useState(false);
    let yearArray = []
    for (let i = 1; i < 4; i++) {
        yearArray.push(presentYear - i)
    }

    const [gallery, setGallery] = useState([]);

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const handleYearChange = async (event: SelectChangeEvent) => {
        if (event.target.value !== 'Select year') {
            setYear(event.target.value)
            setSelectedAlbum('')
            setGallery([])
            setLoading(true)
            await getGallery(event.target.value).then((gallery) => {
                if (gallery) {
                    if (gallery.length > 0) {
                        setGallery(gallery)
                    }
                }
            })
            setLoading(false)
        }
    }
    const handleSelectedGallery = async (event: SelectChangeEvent) => {
        setSelectedAlbum(event.target.value)
        let response = await getImages(year, event.target.value)
        //console.log('selected gallery', response)
        if (response) {
            //console.log('yes response', response)
            handleImages(response.image)
        }
    }

    const handleLogoDisplay = () => GET_LOGO(isInternationalURL ? MAVINTERNATIONAL : MAVVIDYASHRAM);

    return (
        <>
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '1em'
            }}>
                <img style={{ width: isInternationalURL?'20vh':'50vh', textAlign: 'center' }} src={handleLogoDisplay()} />
            </div>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <InputLabel id="select-year">Select year</InputLabel>
                            <Select
                                labelId="select-year"
                                id="select-year-id"
                                value={year}

                                label="Year"
                                onChange={handleYearChange}
                            >
                                <MenuItem value={'Select year'}>{'Select year'}</MenuItem>
                                {yearArray.map((number) => {
                                    return <MenuItem value={number}>{number}</MenuItem>
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    {loading && <Grid item xs={8}><CircularProgress/></Grid>}
                    {gallery.length > 0 && <Grid item xs={8}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select gallery name</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedAlbum}
                                label="Gallery"
                                onChange={handleSelectedGallery}
                                disabled={gallery.length < 1}
                            >
                                {
                                    gallery &&
                                    gallery?.map((item: { title: string, path: string }) => {
                                        return <MenuItem value={item.path}>{item.title}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>}

                </Grid>
            </Box>
        </>
    )
}

export default HeaderComp;