import GalleryWrapComponent from './components/galleryWrapper/galleryWrapper';
import './App.css';


function App() {
  return (
    
    <GalleryWrapComponent/>
  );
}

export default App;
